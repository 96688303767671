@import url(https://fonts.cdnfonts.com/css/panichesta-personal-use);
@import url(https://fonts.cdnfonts.com/css/br-cobane);

@font-face {
  font-family: 'Roobert';
  src: url('https://db.onlinewebfonts.com/t/d6ae884b0a2f1a5346717fc160db2d28.eot');
  src: url('https://db.onlinewebfonts.com/t/d6ae884b0a2f1a5346717fc160db2d28.eot?#iefix') format('embedded-opentype'),
       url('https://db.onlinewebfonts.com/t/d6ae884b0a2f1a5346717fc160db2d28.ttf') format('truetype'),
       url('https://db.onlinewebfonts.com/t/d6ae884b0a2f1a5346717fc160db2d28.svg#Roobert') format('svg');
}

@font-face {
  font-family: 'Roobert';
  src: url(/static/media/RoobertPRO-Regular.d533abd8.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url(/static/media/RoobertPRO-Light.03dfb6a9.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url(/static/media/RoobertPRO-Medium.dfb10097.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url(/static/media/RoobertPRO-Bold.57820505.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'Roobert', sans-serif;
}
